.card {
    display: flex;
    align-items: center; /* Center items vertically */
    padding: 5px; /* Add padding to the card for better appearance */
    border: 1px solid #ccc; /* Add a border for better visibility */
    border-radius: 8px; /* Add border-radius for rounded corners */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
    width: 100%; /* Set the initial width to fill available space */
    max-width: 17rem; /* Set maximum width for the card */
}

.cardTextContainer {
    flex-grow: 1; /* Allow the text container to grow and take available space */
    text-align: left; /* Left-align text */
}

.card h4 {
    margin: 0; /* Remove default margin for better alignment */
}

.card p {
    margin: 0;
}

.cardIcon {
    width: 25px;
    height: 25px;
    margin-right: 10px; /* Add margin to the right of the icon for spacing */
}
