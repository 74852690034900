/* src/components/Alert/alert.module.css */

.alert {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    text-align: center;
    font-size: 1rem;
    width: 100%;
  }
  
  .success {
    background-color: #99f4ae;
    color: #1b5328;
    border: 1px solid #c3e6cb;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .error {
    background-color: #ee9da4;
    color: #df0e23;
    border: 1px solid #f5c6cb;
    font-family: 'Times New Roman', Times, serif;
  }
  