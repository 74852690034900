.button {
    background-color: rgba(12, 128, 223, 0.851);
    color: white;
    border: none;
    padding: 8px 13px;
    font-size: 13px;
    cursor: pointer;
    border-radius: 25px;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0f3e58;
}

/* Responsive styles */
@media (max-width: 768px) {
    .button {
        padding: 10px 15px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .button {
        padding: 12px 18px;
        font-size: 16px;
    }
}
