/* Keyframes for slide-in animations */
@keyframes slideInFromRight {
    0% {
        transform: translateX(20%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-50%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Overall container for the home section */
.homeContainer {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative; /* Ensure relative positioning for the absolute overlay */
}

/* Styling for the "Who We Are" section */
.section1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto; /* Center align the section */
}

/* Title styling for section1 */
.section1Title {
    font-size: 2.9375rem; /* 47px */
    color: #3f4141;
    font-weight: bolder;
    animation: slideInFromLeft 1s ease-out; /* Apply slide-in from right animation */
}

/* Text styling for section1 */
.section1Text {
    font-size: 1.1rem;
    color: #3f4141;
    margin-top: -1rem; /* -22px */
    width: 100%;
    max-width: 1000px; /* Limit the maximum width for better readability */
    animation: slideInFromRight 1s ease-out; /* Apply slide-in from left animation */
}

/* Background image section */
.section2 {
    background-image: url('../../assets/whoWeArePic.jpg');
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 80%;
    background-attachment: fixed;
    filter: brightness(30%); /* Adjust brightness to make it duller */
}

/* Container for overlay text */
.textContainer {
    z-index: 1; /* Ensure the text is above the background */
    animation: zoomIn 1s ease-out; /* Apply zoom-in animation */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 90px 0;
}

.homeButton {
    background-color: transparent;
    color: white;
    border-radius: 25px;
    height: 44px;
    width: 170px;
    border: 2.5px solid white;
    font-weight: 550;
    margin-top: 30px;
    font-size: 15px;
    letter-spacing: 1.5px;
    margin-top: '-40px';
    cursor: pointer;
  }