.footer {
    background-color: white;
    color: black;
    padding: 10px 0;
    text-align: center;
  }
  
  .footerContent {
    max-width: 1200px;
    margin: 0 auto;
  }
  