/* Logo container styling */
.logoContainer {
    display: flex;
    align-items: center; /* Vertically align the logo and text */
    gap: 10px; /* Adjust this value to reduce the space between the logo and text */
}

/* Logo styling */
.logo {
    height: 70px;
    width: 100px;
}

/* Logo text styling */
.logoText {
    font-size: 18px; /* Adjust font size as necessary */
    font-weight: bold;
    margin: 0; /* Remove default margin */
}

/* Navbar styling */
.navbar {
    background-color: white; /* Add transparency for floating effect */
    display: flex;
    justify-content: center; /* Center all content horizontally */
    align-items: center;
    position: fixed; 
    top: 0;
    width: 100%; 
    height: 70px;
    z-index: 1000;
    backdrop-filter: blur(10px); /* Add a blur effect for better visibility */
    box-shadow: 0 2px 10px rgba(10, 10, 10, 0.1); 
}

/* Navbar inner container */
.navbarInnerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 30px;
    max-width: 1200px; /* Limit the maximum width */
}

/* Basic styling for navbar links */
.links {
    display: flex;
    cursor: pointer;
}

.links a {
    color: rgb(48, 41, 41);
    text-decoration: none;
    padding: 14px 25px;
    font-size: 14px;
    font-weight: 600;
}

.links a:hover,
.links a.active {
    color: rgba(12, 128, 223, 0.851);
}

/* Styling for web view */
@media screen and (min-width: 769px) {
    .menuButton {
        display: none; /* Hide the menu button on larger screens */
    }
}

/* Styling for mobile menu */
@media screen and (max-width: 768px) {
    .logo {
        height: 50px;
        width: auto; /* Maintain aspect ratio */
    }
    .logoText {
        height: 50px;
        margin-top: 20px;
        width: auto; /* Maintain aspect ratio */
    }

    .navbar {
        height: 50px;
    }

    .links {
        display: none; /* Hide the links by default on smaller screens */
        flex-direction: column;
        position: absolute;
        top: 50px; /* Adjust as needed */
        left: 0;
        width: 100%;
        background-color: rgba(51, 51, 51, 0.9); /* Add transparency */
        transition: all 0.3s ease;
    }

    .navbar.open .links {
        display: flex; /* Display the links when the menu is open */
    }

    .links a {
        color: white; /* Ensure the text is visible on dark background */
        padding: 10px 20px;
        border-bottom: 1px solid #ddd;
    }
}

/* Add styles for the active link */
.active {
    color: blue;
}
