.mainContainer {
  margin: 20px;
}

.textContent {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center;
  margin-bottom: 20px;
}

/***************************************************************************************************/

.container1 {
  display: flex;
  flex-direction: row; /* Arrange items horizontally */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
}

.imageContainer {
  max-width: 600px; /* Limit the maximum width of the image container */
  width: 100%; /* Ensure the image container fills its parent width */
 max-height: 400px;
}

.imageContainer img {
  width: 100%; /* Ensure the image fills its container width */
  height: auto; /* Maintain aspect ratio */
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 500px;
  max-height: 400px;
}

/* Apply styles to alternate cards for zigzag layout */
.cardContainer > * {
  margin-bottom: 20px; /* Add bottom margin for spacing */
}

/* Apply styles to alternate cards for zigzag layout */
.cardContainer > :nth-child(even) {
  margin-left: 90px;
}

@media (max-width: 768px) {
  .container1 {
      flex-direction: column; /* Change to column layout on smaller screens */
      align-items: center; /* Center items vertically */
  }

  .imageContainer {
      margin-right: 0; /* Remove margin on smaller screens */
      margin-bottom: 20px; /* Add margin to the bottom for spacing */
      max-width: 500px;
  }
  
  .cardContainer {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr)); /* Adjust card columns based on available space */
}
  
}

/***************************************************************************************************/
.container2 {
  display: flex;
  flex-direction: column; /* Stack by default for smaller screens */
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.content2 {
  flex: 1;
  padding: 20px;
}

.heading {
  color: darkblue;
  font-size: 2rem;
  font-weight: bolder;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.text {
  font-size: 1rem;
  line-height: 1.5;
}

.imageContainer2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0; /* Space between the image and text */
}

.responsiveImage2 {
  width: 100%;
  max-width: 600px;
  max-height: 450px;
}

/* Media Queries for responsiveness */
@media (min-width: 768px) {
  .container2 {
    flex-direction: row; /* Row layout on larger screens */
    align-items: flex-start; /* Align items at the start */
  }

  .content2 {
    padding: 20px;
    margin-right: 20px; /* Space between text and image */
  }

  .imageContainer2 {
    margin: 0; /* Remove margin for row layout */
  }
}

/***************************************************************************************************/

.container3 {
  display: flex;
  flex-direction: column; /* Stack content vertically on smaller screens */
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.imageContainer3 {
  width: 100%;
  max-width: 500px;
  max-height: 400px;
}

.imageContainer3 img {
  width: 100%;
  height: 300px;
  max-width: 500px;
  max-height: 400px;
}

.content3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the left */
  width: 100%;
  max-width: 500px; /* Set a max-width for content */
  margin-left: 20px;
  margin-right: 20px;
}

.heading {
  font-size: 1.7rem;
  color: darkblue;
  margin-bottom: 20px;
}

.paragraph {
  font-size: 14px;
  margin-bottom: 20px; /* Space between paragraphs */
}

/* Media Queries for responsiveness */
@media (min-width: 768px) {
  .container3 {
      flex-direction: row; /* Row layout on larger screens */
      justify-content: flex-start; /* Align items to the left */
      align-items: flex-start; /* Align items to the top */
  }

  .imageContainer3 {
    margin-top: 50px;
      margin-bottom: 0; /* Remove bottom margin for row layout */
      margin-right: 20px; /* Fixed space between image and content */
      flex-shrink: 0; /* Prevent image from shrinking */
  }

  .content3 {
      max-width: calc(100% - 500px); /* Ensure content doesn't overflow */
  }
}

/************************************************************************************************************/

.container4 {
  display: flex;
  flex-direction: column; /* Stack image and content vertically on small screens */
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.imageTransformation {
  width: 100%;
  max-width: 330px;
  margin-bottom: 20px; /* Space between image and text on smaller screens */
}

.imageTransformation img {
  width: 100%;
  height: auto;
  max-width: 300px;
  max-height: 170px;
}

.transformation {
  display: flex;
  flex-direction: column; /* Single column by default */
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
  max-width: 550px; /* Limit the width to avoid clumsy look */
}

.transformationColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 300px;
}

/* Media Queries for responsiveness */
@media (min-width: 768px) {
  .container4 {
      flex-direction: row; /* Row layout on larger screens */
      justify-content: center; /* Center the container content */
      align-items: flex-start; /* Align items to the top */
  }

  .imageTransformation {
      margin-bottom: 0; /* Remove bottom margin for row layout */
      margin-right: 20px; /* Space between image and content */
  }

  .transformation {
      display: grid;
      grid-template-columns: 1fr 1fr; /* Two columns layout on larger screens */
      gap: 20px; /* Space between columns */
  }

  .transformationColumn {
      width: 100%;
      
  }
}
